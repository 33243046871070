//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { difference } from 'lodash'

import { isMatch, transformEntityName } from '@/utils'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import EntitySectionList from '@/components/UI/EntitySectionList'
import { isChildOf } from '@/utils/DOM'
import { contactsStore } from '@/store'

export default {
  name: 'ContactPicker',
  components: {
    'close-icon': createSVGComponent({ icon: 'close', size: 14, sizeStorage: 16 }),
    'entity-section-list': EntitySectionList,
  },
  props: ['value'],
  data () {
    return {
      filter: '',
      selected: 0,
      isOpen: false,
    }
  },
  computed: {
    contacts () {
      return this.value.map(jid => contactsStore.getters.contact(jid) || {})
    },
    fromContacts () {
      return difference(contactsStore.getters.teamMembers, this.contacts).filter(contact => isMatch(contact.displayName, this.filter))
    },
    sections () {
      return [{
        name: 'Contacts',
        entities: this.fromContacts,
      }]
    },
  },
  created () {
    this.handleWindowClick = event => {
      event.stopImmediatePropagation()
      event.stopPropagation()

      const { target } = event
      const isChild = isChildOf(target, this.$el)
      if (isChild) return

      this.isOpen = false
    }
    window.addEventListener('click', this.handleWindowClick)
  },
  beforeDestroy () {
    this.handleWindowClick && window.removeEventListener('click', this.handleWindowClick)
  },
  methods: {
    icon (jid) {
      return contactsStore.getters.contactIcon(jid)
    },
    blur () {
      this.isOpen = false

      const { input } = this.$refs
      input && input.blur()
    },
    transformEntityName,
    toggle (item) {
      if (typeof item === 'number') {
        item = this.fromContacts[item]
      }

      if (!item) return

      if (typeof item !== 'string') {
        item = item.jid
      }

      const data = [...this.value]
      const index = data.indexOf(item)
      if (index >= 0) {
        data.splice(index, 1)
      } else {
        data.push(item)
        this.$nextTick(() => {
          const { input } = this.$refs
          input && input.focus()
        })
      }
      this.$emit('input', data)
    },
  },
}
