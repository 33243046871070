//
//
//
//
//
//
//
//

export default {
  name: 'AdaptiveAvatar',
  props: ['url'],
  data () {
    return {
      isLoaded: false,
      isWide: false,
    }
  },
  methods: {
    onLoad (e) {
      if (!e.target) return

      this.isLoaded = true

      const { naturalWidth, naturalHeight } = e.target
      this.isWide = naturalWidth > naturalHeight
    },
  },
}
