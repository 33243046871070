//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AdaptiveAvatar from '@/components/UI/AdaptiveAvatar'
import { contactsStore } from '@/store'

export default {
  name: 'EntityList',
  components: {
    avatar: AdaptiveAvatar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    compact: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Number,
      default: -1,
    },
    selectedOnes: {
      type: Array,
      default: null,
    },
  },
  methods: {
    getIcon (item) {
      return contactsStore.getters.contactIcon(item.jid) ?? item.icon
    },
  },
}
