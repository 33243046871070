//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */

import Task from '@/components/Tasks/TaskBarCardFull'
import { findMatches } from './Helper'
import { uiStore, tasksStore, teamsStore } from '@/store'

export default {
  name: 'TaskComponent',
  components: {
    task: Task,
  },
  props: ['data', 'filter'],
  created () {
    const task = this.data
    if (task && !tasksStore.state.data[task.jid]) {
      tasksStore.mutations.addTask({ jid: task.jid, task })
    }
  },
  mounted () {
    const taskElm = this.$el.firstElementChild
    if (!taskElm) return

    const titleElm = taskElm.querySelector('.task-num')
    titleElm && findMatches(titleElm, this.filter)

    const textElm = taskElm.querySelector('.task-card h5')
    textElm && findMatches(textElm, this.filter)
  },
  methods: {
    handleClick () {
      const dialogOptions = {
        caption: this.$t('common.openTaskDialog.caption'),
        icon: this.data.icon,
        content: this.$t('common.openTaskDialog.quest'),
        formatData: { num: '' + this.data.num },
        acceptCallback: async () => {
          this.$router.push({
            name: 'Chat',
            params: {
              teamId: teamsStore.getters.currentTeam.uid,
              jid: this.data.jid,
            },
          })
        },
        acceptLabel: this.$t('common.move'),
        elementShowcase: this.$el.cloneNode(true),
      }

      uiStore.actions.showModal({
        instance: 'Dialog',
        payload: dialogOptions,
      })
    },
  },
}
