//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '@/components/Modals/Default/styles.scss'

import { isEqual } from 'lodash'
import { defaultLogger } from '@/loggers'

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { mapGetters } from 'vuex'

import SearchTape from './SearchTape'
import SearchControls from './SearchControls'

import SearchRequests from './SearchRequests'
import { isEscape } from '@/utils/KeyUtils'
import { debounce, format } from 'quasar'

import {
  teamsStore,
  uiStore,
} from '@/store'

import { getFormattedTotal, isMaxTotal } from '@/utils'
const { capitalize } = format

export default {
  name: 'GlobalSearch',
  components: {
    'close-icon': createSVGComponent({ icon: 'gall_close', sizeStorage: 'raw', size: 24 }),
    'search-icon': createSVGComponent({ icon: 'search', size: 24 }),

    SearchTape,
    SearchControls,
  },
  data () {
    return {
      pages: {},
      searchInFocus: false,

      innerSearchQuery: '',
      searchQuery: '',

      currentPage: 'main',
    }
  },
  computed: {
    ...mapGetters([
      'currentChat',
    ]),
    currentMiddleColumnPayload () {
      return uiStore.getters.currentMiddleColumnPayload
    },
  },
  watch: {
    innerSearchQuery (value) {
      if (value.length >= this.minQueryLength) this.findMatches(this.currentPage, value)
    },
  },
  mounted () {
    // hate to use mounted, but autofocus does not seem to work
    // something else could be interfering with it
    // anyhow, this component is about to roll 7 on the dice
    // and this does not seem to be this bad of an approach
    // so here we go - manual autofocus
    this.$refs.searchInput && this.$refs.searchInput.focus()
  },
  created () {
    this.requests = new SearchRequests()
    this.findMatches = debounce(this._findMatches, 200)

    const main = {
      caption: capitalize(this.$t('globalSearch.all')),
      total: 0,
      totalString: '0',
      isLoading: false,
      hasMore: false,
      filter: null,
      init: true,
    }
    const messages = {
      caption: capitalize(this.$tc('glossary.message', 2)),
      matches: [],
      total: 0,
      totalString: '0',
      isLoading: false,
      hasMore: false,
      filter: null,
      init: false,
    }
    const tasks = {
      caption: capitalize(this.$t('glossary.task_plural')),
      matches: [],
      total: 0,
      totalString: '0',
      isLoading: false,
      hasMore: false,
      filter: null,
      init: false,
    }
    const contacts = {
      caption: capitalize(this.$t('glossary.contact_plural')),
      matches: [],
      total: 0,
      totalString: '0',
      isLoading: false,
      hasMore: false,
      filter: null,
      init: false,
    }
    const groups = {
      caption: capitalize(this.$t('glossary.group_plural')),
      matches: [],
      total: 0,
      totalString: '0',
      isLoading: false,
      hasMore: false,
      filter: null,
      init: false,
    }
    const files = {
      caption: capitalize(this.$t('glossary.file_plural')),
      matches: [],
      total: 0,
      totalString: '0',
      isLoading: false,
      hasMore: false,
      filter: null,
      init: false,
    }

    this.pages = teamsStore.getters.isSingleGroupTeam()
      ? { main, messages, contacts, files }
      : { main, messages, tasks, contacts, groups, files }

    this.escapeKeyPressed = e => {
      isEscape(e) && this.close()
    }
    window.addEventListener('keyup', this.escapeKeyPressed)

    const payload = this.currentMiddleColumnPayload || {}

    this.minQueryLength = payload.minQueryLength
    this.predefinedFilter = null
    if (payload.chatOnly) {
      this.currentPage = 'messages'
      this.predefinedFilter = {
        messages: { chats: [this.currentChat] },
      }
    }

    this.innerSearchQuery = payload.searchQuery || ''
  },
  beforeDestroy () {
    this.requests && this.requests.reset()
    this.escapeKeyPressed && window.removeEventListener('keyup', this.escapeKeyPressed)
  },
  methods: {
    handleFilterChange (params) {
      if (!params) return

      const { pageName, filter } = params
      const page = this.pages[pageName]
      if (!page) return

      const same = isEqual(page.filter, filter)
      if (same) return

      this.$set(page, 'filter', filter)

      const query = this.innerSearchQuery // .length >= 3 ? this.innerSearchQuery : this.searchQuery
      // if (query.length < 3) return

      this.findMatches(pageName, query, false, page.init)
    },
    close () {
      uiStore.actions.resetMiddleColumnInstance(true)
    },
    maskClick (event) {
      event.target === event.currentTarget && this.close()
    },
    reset () {
      this.requests.reset()

      Object.keys(this.pages).forEach(key => {
        const page = this.pages[key]

        this.$set(page, 'total', 0)
        this.$set(page, 'totalString', '0')
        this.$set(page, 'isLoading', true)
        key !== 'main' && this.$set(page, 'matches', [])
      })
    },
    setPageMatches (pageName, matches, total, hasMore) {
      if (pageName === 'main') return

      matches = matches || []
      const pageObject = this.pages[pageName]

      if (!pageObject.init) {
        this.$set(pageObject, 'init', true)
        this.predefinedFilter && (delete this.predefinedFilter[pageName])
      }

      this.$set(pageObject, 'matches', [...pageObject.matches, ...matches])
      this.$set(pageObject, 'hasMore', hasMore)
      this.$set(pageObject, 'isLoading', false)
      this.$set(pageObject, 'total', total)
      this.$set(pageObject, 'totalString', getFormattedTotal(total))

      const keys = Object.keys(this.pages).filter(key => key !== 'main')
      const somethingLoading = keys.some(key => this.pages[key].isLoading)
      this.$set(this.pages.main, 'isLoading', somethingLoading)

      const initial = 0
      const amount = keys.reduce((accumulator, key) => accumulator + this.pages[key].total, initial)
      const amountString = `${amount}${keys
        .some(item => isMaxTotal(this.pages[item].total)) ? '+' : ''}`

      this.$set(this.pages.main, 'total', amount)
      this.$set(this.pages.main, 'totalString', amountString)
    },
    async requestPageData (pageName, query) {
      let matches = []
      let total = 0
      let hasMore = false
      try {
        const pageObject = this.pages[pageName]
        const result = await this.requests.getData(pageName, query, pageObject.filter)
        matches = result.matches
        total = result.total
        hasMore = result.hasMore
      } catch (e) {
        defaultLogger.warn(e)
      }
      this.setPageMatches(pageName, matches, total, hasMore)
    },
    async _findMatches (pageName, query, next, single) {
      // Yandex Metrika goal for global searching and specific chat searching
      // FOR GLOBAL: next === undefined && single === undefined
      const searchSpecificChat = next === false && single === false
      window.goal('search', { searchType: searchSpecificChat ? 'Поиск в конкретном чате' : 'Глобальный поиск' })

      this.searchQuery = query
      if (next && pageName !== 'main') {
        this.$set(this.pages[pageName], 'isLoading', true)
        return this.requestPageData(pageName, query)
      } else if (single) {
        if (pageName === 'main') return
        this.requests.resetPagination(pageName)
        const page = this.pages[pageName]

        this.$set(page, 'total', 0)
        this.$set(page, 'totalString', 0)
        this.$set(page, 'isLoading', true)
        this.$set(page, 'matches', [])

        this.requestPageData(pageName, query)
        return
      }

      this.reset()

      const pageNames = Object.keys(this.pages)
      const index = pageNames.indexOf(pageName)
      if (index >= 0) {
        const tmp = pageNames[index]
        pageNames.splice(index, 1)
        pageNames.unshift(tmp)
      }

      for (const key of pageNames) {
        if (key === 'main') continue
        this.requestPageData(key, query)
      }
    },
  },
}
