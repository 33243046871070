//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from './PageMixin'

import FileItem from '@/components/RightBar/FileBrowser/FileItem'

export default {
  components: {
    'file-item': FileItem,
  },
  mixins: [PageMixin],
}
