//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DateTimeSelector from '@/components/UI/DateTimeSelector'

export default {
  name: 'DateBar',
  components: {
    DateTimeSelector,
  },
  props: ['label', 'value', 'placeholder', 'disableTo', 'disableFrom'],
  data () {
    return {
      showDatePicker: false,
    }
  },
  methods: {
    handleInput (value) {
      if (!value) {
        this.$emit('input', null)
        return
      }

      if (typeof value === 'string') {
        value = new Date(value)
      }

      this.$emit('input', value)
    },
    handleRawInput (value) {
      if (!value) {
        this.$emit('raw-input', null)
        return
      }

      this.$emit('raw-input', value)
    },
    format (date) {
      if (!date) return ''

      if (typeof date === 'string') {
        date = new Date(date)
      }

      if (date instanceof Date) {
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const day = ('0' + date.getDate()).slice(-2)
        const year = date.getFullYear()
        return `${day}:${month}:${year}`
      }
      return '?'
    },
  },
}
