//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SearchPageMenu',
  props: {
    value: {
      type: String,
      required: true,
    },
    pages: {
      type: Object,
      required: true,
    },
  },
  computed: {
    keys () {
      return Object.keys(this.pages)
    },
    barOffset () {
      return this.keys.indexOf(this.value) * 36
    },
  },
}
