//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './pages/page.styles.scss'

import ScrollableArea from '@/components/UI/ScrollableArea'

import BaseSpinner from '@/components/UI/BaseSpinner.vue'

import Messages from './pages/Messages'
import Tasks from './pages/Tasks'
import Contacts from './pages/Contacts'
import Groups from './pages/Groups'
import Files from './pages/Files'

export default {
  name: 'GlobalSearchTape',
  components: {
    BaseSpinner,
    'scrollable-area': ScrollableArea,

    messages: Messages,
    tasks: Tasks,
    contacts: Contacts,
    groups: Groups,
    files: Files,
  },
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
    pages: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentPageObject () {
      return this.pages[this.page] || {}
    },
    isSomethingLoading () {
      return Object.keys(this.pages).some(pageName => {
        if (pageName === 'main') return false

        return this.pages[pageName].isLoading
      })
    },
    isNoMatches () {
      return this.currentPageObject.total === 0 && !this.currentPageObject.isLoading && this.searchQuery
    },
  },
  watch: {
    page () {
      const { scrollableArea } = this.$refs
      scrollableArea && scrollableArea.scrollTop()
    },
  },
  created () {
    this.maxPreviewMatches = 3
  },
  mounted () {
    const { scrollableArea } = this.$refs
    scrollableArea && scrollableArea.setUpdateCallback(this.scrollUpdate)
  },
  methods: {
    matchesPlural (page) {
      return `${page.totalString} ${this.$tc('glossary.match', page.total)}`
    },
    handlePageInit () {
      const { scrollableArea } = this.$refs
      if (!scrollableArea) return

      const contentHeight = scrollableArea.getContentHeight(true)
      const contentFrameHeight = scrollableArea.getHeight()

      contentHeight < contentFrameHeight && this.tryLoadNext()
    },
    tryLoadNext () {
      const pageName = this.page
      if (pageName === 'main') return

      const page = this.pages[pageName]
      if (!page.hasMore) return false

      this.$emit('onTryLoadNext', pageName)
    },
    scrollUpdate (scrollTop, scrollHeight, clientHeight, manually) {
      if (!manually) return

      const isThresholdPassed = scrollHeight - scrollTop - clientHeight < 100
      isThresholdPassed && this.tryLoadNext()
    },
  },
}
