//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '@/components/RightBar/FileBrowser/FilterPanel/Lists/style.scss'

import { mapGetters } from 'vuex'

import PageMixin from './PageMixin'

import FilterBar from '@/components/RightBar/FileBrowser/FilterPanel/FilterBar'
import ChatList from '@/components/RightBar/FileBrowser/FilterPanel/Lists/ChatList'
import { entityTransform } from '@/components/RightBar/FileBrowser/FilterPanel/Lists/Utils.ts'

import FileTypeList from '@/components/RightBar/FileBrowser/FilterPanel/Lists/FileTypeList'

import DateBar from './Common/DateBar'

import { format } from 'quasar'

import { teamsStore } from '@/store'

const { capitalize } = format

export default {
  components: {
    'filter-bar': FilterBar,
    'chat-list': ChatList,
    'file-type-list': FileTypeList,

    DateBar,
  },
  mixins: [PageMixin],
  data () {
    return {
      chats: [],
      chatFilter: '',

      senders: [],
      senderFilter: '',

      fileTypes: [],

      from: null,
      to: null,
      rawFrom: null,
      rawTo: null,
    }
  },
  watch: {
    chats (list) {
      const chats = list.map(list => list.value)
      this.emitFilter({ chat: chats.length > 0 ? chats.join() : null })
    },
    senders (list) {
      const senders = list.map(list => list.value)
      this.emitFilter({ sender: senders.length > 0 ? senders.join() : null })
    },
    fileTypes (types) {
      this.emitFilter({ type: types.length > 0 ? types.map(type => type.value).join() : null })
    },
    from (date) {
      this.emitFilter({ date_from: this.transformDate(date) })
    },
    to (date) {
      this.emitFilter({ date_to: this.transformDate(date) })
    },
  },
  created () {
    this.chats = (this.startFilter.chats || []).map(jid => {
      return entityTransform(this.entity(jid) || {})
    })
  },
  computed: {
    ...mapGetters([
      'entity',
    ]),
    showFileTypesFilter () {
      return this.pageName === 'files'
    },
    showChatsFilter () {
      return !teamsStore.getters.isSingleGroupTeam()
    },
    senderText () {
      return capitalize(this.$t('glossary.sender')) + ':'
    },
    // showClearButton () {
    //   return this.chats.length > 0 || this.chatFilter || this.from || this.to
    // }
  },
  methods: {
    transformDate (date) {
      if (!date) return null

      return date.toJSON()
    },
    emitFilter (filter) {
      this.$emit('onFilterAppend', filter)
    },
    clearFilter () {
      this.chats = []
      this.chatFilter = ''

      this.senders = []
      this.senderFilter = ''

      this.from = this.to = null

      this.fileTypes = []

      const { chatFilterBar } = this.$refs
      chatFilterBar && chatFilterBar.clearInput && chatFilterBar.clearInput()
    },
  },
}
