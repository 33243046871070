//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from './PageMixin'

import ContactCard from '@/components/RightBar/Contacts/ContactCard'

export default {
  components: {
    'contact-row': ContactCard,
  },
  mixins: [PageMixin],
  computed: {
    sortedContacts () {
      return this.matches.sort((a, b) => !a.isArchive && b.isArchive ? -1 : a.isArchive && b.isArchive ? 0 : 1)
    },
  },
}
