import { isMatch, loadMissingChatsFromMessages } from '@/utils'
import api from '@/api/v3'
import Pagination from '@/api/v3/Pagination'
import store, { contactsStore, groupsStore } from '@/store'
import { Contact } from '@tada-team/tdproto-ts'

interface Result {
  matches: Array<any>;
  total: number;
  hasMore: boolean;
}

export default class {
  messagesPagination: Pagination | null
  tasksPagination: Pagination | null
  filesPagination: Pagination | null

  paginationLimit = 25

  constructor () {
    this.messagesPagination = null
    this.tasksPagination = null
    this.filesPagination = null
  }

  reset () {
    this.resetPagination('messages')
    this.resetPagination('tasks')
    this.resetPagination('files')
  }

  resetPagination (id: string) {
    switch (id) {
      case 'messages': {
        if (this.messagesPagination) {
          this.messagesPagination.cancel()
          this.messagesPagination = null
        }
        break
      }
      case 'tasks': {
        if (this.tasksPagination) {
          this.tasksPagination.cancel()
          this.tasksPagination = null
        }
        break
      }
      case 'files': {
        if (this.filesPagination) {
          this.filesPagination.cancel()
          this.filesPagination = null
        }
        break
      }
    }
  }

  async getData (page: string, searchQuery: string, filter: Record<string, any> | null): Promise<Result> {
    switch (page) {
      case 'messages': return this.getMessages(searchQuery, filter)
      case 'files': return this.getFiles(searchQuery, filter)
      case 'tasks': return this.getTasks(searchQuery, filter)
      case 'contacts': return this.getContacts(searchQuery)
      case 'groups': return this.getGroups(searchQuery)
      default: return { matches: [], total: 0, hasMore: false }
    }
  }

  async getFiles (searchQuery: string, filter: Record<string, any> | null): Promise<Result> {
    if (!this.filesPagination) {
      const params = { text: searchQuery, has_upload: true }
      filter && Object.assign(params, filter)
      this.filesPagination = api.messages.filter(params, this.paginationLimit)
    }
    this.filesPagination.cancel()
    const matches = await this.filesPagination.next()

    // load chats that are not yet present locally
    // this is needed to display where a file was sent to
    matches && loadMissingChatsFromMessages(matches)

    return {
      matches: matches || [],
      total: this.filesPagination.count ?? 0,
      hasMore: this.filesPagination.hasMore(),
    }
  }

  async getContacts (searchQuery: string): Promise<Result> {
    let matches: Contact[] = contactsStore.getters.contactList
    matches = matches.filter(contact => {
      const byName = isMatch(contact.displayName, searchQuery)
      const byRole = isMatch(contact.role, searchQuery)
      return byName || byRole
    })
    return { matches, total: matches.length, hasMore: false }
  }

  async getGroups (searchQuery: string): Promise<Result> {
    let matches = groupsStore.getters.list
    matches = matches.filter(group => {
      return isMatch(group.displayName, searchQuery)
    })
    return { matches, total: matches.length, hasMore: false }
  }

  async getMessages (searchQuery: string, filter: Record<string, any> | null): Promise<Result> {
    if (!this.messagesPagination) {
      const params = { text: searchQuery } as any
      filter && Object.assign(params, filter)
      this.messagesPagination = api.messages.filter(params, this.paginationLimit)
    }
    this.messagesPagination.cancel()
    const matches = await this.messagesPagination.next()
    return {
      matches: matches || [],
      total: this.messagesPagination.count ?? 0,
      hasMore: this.messagesPagination.hasMore(),
    }
  }

  async getTasks (searchQuery: string, filter: any | null): Promise<Result> {
    if (!this.tasksPagination) {
      const params = {
        q: searchQuery,
      } as any

      if (filter) {
        const { getters } = store
        const me = getters.getUserId

        const { type, status, contacts, inObservers } = filter
        switch (type) {
          case 1: {
            params.assignee = me
            break
          }
          case 2: {
            params.owner = me
            break
          }
          case 3: {
            params.observer = me
            break
          }
        }

        switch (status) {
          case 0: {
            delete params.task_status
            break
          }
          case 1: {
            params.task_status = 'new'
            break
          }
          case 2: {
            params.task_status = 'done'
            break
          }
        }

        if (contacts) {
          const list = contacts.join()
          inObservers ? (params.observer = list) : (params.member = list)
        }
      }

      this.tasksPagination = api.tasks.filter(params, this.paginationLimit)
    }
    this.tasksPagination.cancel()
    const matches = await this.tasksPagination.next()
    return {
      matches: matches || [],
      total: this.tasksPagination.count ?? 0,
      hasMore: this.tasksPagination.hasMore(),
    }
  }
}
