export default {
  props: {
    pageName: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    predefinedFilter: {
      type: Object,
      default: null,
    },
  },
  computed: {
    startFilter () {
      return (this.predefinedFilter && this.predefinedFilter[this.pageName]) || {}
    },
  },
}
