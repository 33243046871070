//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from './PageMixin'

import TaskComponent from './TaskComponent'

export default {
  components: {
    'task-component': TaskComponent,
  },
  mixins: [PageMixin],
}
