//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from './PageMixin'

import ContactCard from '@/components/RightBar/Contacts/ContactCard'

export default {
  components: {
    'contact-row': ContactCard,
  },
  mixins: [PageMixin],
}
