//
//
//
//

import { create } from '../DOM/'
import { applyMoveByClick, applyFilterHighlight, setMaxMessageContentWidth } from './Utils'

export default {
  name: 'MessageComponentHook',
  props: ['data', 'filter'],
  mounted () {
    const { parentNode: container } = this.$el
    container && setMaxMessageContentWidth(container.scrollWidth)

    const message = this.data
    const element = create({ message, fully: true })

    applyMoveByClick(element, message)
    this.filter && applyFilterHighlight(this.filter, element, message)

    this.$el.appendChild(element)
  },
}
