//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EntityList from './EntityList'
import ScrollableArea from './ScrollableArea'
export default {
  name: 'EntitySectionList',
  components: {
    EntityList,
    ScrollableArea,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    dividers: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: Number,
      default: 300,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      listHeight: 0,
    }
  },
  computed: {
    contactsAmount () {
      let result = 0
      if (!this.sections) return result

      for (const index in this.sections) {
        const section = this.sections[index]
        result += section.entities.length
      }
      return result
    },
  },
  watch: {
    sections () {
      this.cachedSelectedData = null
    },
    selected () {
      this.cachedSelectedData = null
      this.$forceUpdate()
    },
  },
  created () {
    this.cachedSelectedData = null
  },
  mounted () {
    this.calculateListHeight()
  },
  updated () {
    this.calculateListHeight()

    const { entityLists } = this.$refs
    if (!entityLists) return

    const list = entityLists[entityLists.length === 1 ? 0 : this.cachedSelectedData ? this.cachedSelectedData.section : 0]
    if (!list) return

    const { $el } = list
    const selected = $el.querySelector('.selected')
    if (!selected) return

    selected.scrollIntoViewIfNeeded ? selected.scrollIntoViewIfNeeded(false) : selected.scrollIntoView()
  },
  methods: {
    selectedIndex (sectionIndex) {
      if (this.sections.length === 1) {
        const section = this.sections[0]
        const length = section.entities.length
        let index = this.selected % length
        index = index < 0 ? index + length : index

        this.$emit('setSelectedData', { section: 0, index })

        return index
      }

      if (this.cachedSelectedData) {
        const { section, index } = this.cachedSelectedData
        return sectionIndex !== section ? -1 : index
      }

      if (!this.selectable) return -1

      let startIndexOffset = 0
      let currentSectionContactsCount = 0
      for (let index = 0; index <= sectionIndex; index++) {
        const section = this.sections[index]
        const contactsCount = section.entities.length
        if (sectionIndex === index) {
          currentSectionContactsCount = contactsCount
          break
        }
        startIndexOffset += contactsCount
      }
      const endIndexOffset = startIndexOffset + currentSectionContactsCount - 1
      let selected = this.contactsAmount !== 0 ? this.selected % this.contactsAmount : 0
      selected = selected < 0 ? selected + this.contactsAmount : selected

      const found = selected >= startIndexOffset && selected <= endIndexOffset

      const result = selected % (currentSectionContactsCount + 1)
      if (found) {
        this.cachedSelectedData = { section: sectionIndex, index: result }
        this.$emit('setSelectedData', this.cachedSelectedData)
      }

      return found ? result : -1
    },
    calculateListHeight () {
      const { scrollableArea } = this.$refs
      if (!scrollableArea) return

      const contentHeight = scrollableArea.getContentHeight()
      const min = Math.min(this.minHeight, contentHeight)

      this.listHeight = contentHeight === 0 ? 0 : (min < this.minHeight ? (min + 20) : min)
    },
    onItemClick (item) {
      this.$emit('itemClick', item)
    },
  },
}
