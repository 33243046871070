export default {
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  mounted () {
    if (this.max !== 0) return

    if (this.data.matches.length > 0) {
      this.$emit('pageInit', this.pageName)
      return
    }

    this.unwatchFn = this.$watch('data.matches', (current, previous) => {
      if (current.length > 0 && previous.length === 0) {
        this.$emit('pageInit', this.pageName)
        this.unwatchFn && this.unwatchFn()
      }
    })
  },
  beforeDestroy () {
    this.unwatchFn && this.unwatchFn()
  },
  computed: {
    matches () {
      return this.max ? this.data.matches.slice(0, this.max) : this.data.matches
    },
  },
}
