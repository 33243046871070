import { highlightMatches, preventHtmlTags, isMatch } from '@/utils'

const createMatchesElm = (text, filter) => {
  if (!isMatch(text, filter)) return

  const elm = document.createElement('span')
  elm.innerHTML = highlightMatches(preventHtmlTags(text), filter)
  return elm
}

export const findMatches = (element, filter) => {
  for (let i = 0; i < element.childNodes.length; i++) {
    const node = element.childNodes[i]
    if (node.nodeType !== Node.TEXT_NODE) continue

    const { textContent } = node

    const matchesElm = createMatchesElm(textContent, filter)
    matchesElm && node.replaceWith(matchesElm)
    break
  }
}
