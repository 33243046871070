//
//
//
//
//
//
//
//
//
//
//
//
//

import SliderChecker from './SliderChecker.jsx'

export default {
  name: 'CheckboxSlider',
  components: {
    SliderChecker,
  },
  props: ['value', 'width', 'height', 'thumbSize', 'textState', 'edgeMargin', 'disabled'],
  // methods: {
  //   handleChange (event) {
  //     const { checked } = event.target
  //     this.$emit('input', checked)

  //     checked && this.$emit('checked')
  //   }
  // }
}
