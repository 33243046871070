//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

export default {
  name: 'CheckBar',
  components: {
    'mark-icon': createSVGComponent({ icon: 'mark_filled', size: 16, permanentlyActive: true }),
  },
  props: ['active', 'label', 'order'],
}
