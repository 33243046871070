//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from './PageMixin'

import MessageComponent from '@/components/Chat/Instance/Hooks/StandaloneMessageHook'

export default {
  components: {
    'message-component': MessageComponent,
  },
  mixins: [PageMixin],
}
