//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from './PageMixin'

import CheckBar from './Common/CheckBar'
import ContactPicker from './Common/ContactPicker'

import CheckboxSlider from '@/components/UI/CheckboxSlider'

export default {
  components: {
    CheckBar,
    ContactPicker,
    'check-box': CheckboxSlider,
  },
  mixins: [PageMixin],
  data () {
    return {
      type: 0,
      status: 0,

      contacts: [],
      inObservers: false,
    }
  },
  watch: {
    type (value) {
      this.$emit('onFilterAppend', { type: value === 0 ? null : value })
    },
    status (value) {
      this.$emit('onFilterAppend', { status: value })
    },
    contacts (value) {
      const isEmpty = value.length === 0
      isEmpty && (this.inObservers = false)
      this.$emit('onFilterAppend', { contacts: isEmpty ? null : value })
    },
    inObservers (value) {
      this.$emit('onFilterAppend', { inObservers: value || null })
    },
  },
  created () {
    // const { getters } = this.$store
    // this.contacts = [getters.getUserId]
  },
  methods: {
    selectType (type) {
      this.type = type
    },
    selectStatus (status) {
      this.status = status
    },
    clearFilter () {
      this.type = 0
      this.status = 0
      this.inObservers = false
      this.contacts = []
    },
  },
}
