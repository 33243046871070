//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './pages/page.styles.scss'
import PageMenu from './PageMenu'

import Tasks from './pages/Tasks'
import Messages from './pages/Messages'

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { extend } from 'quasar'

export default {
  name: 'GlobalSearchControls',
  components: {
    PageMenu,

    tasks: Tasks,
    messages: Messages,
    files: { ...Messages }, // copy component Messages for different state when using in with "is"

    'close-icon': createSVGComponent({ icon: 'close', size: 14, sizeStorage: 16 }),
  },
  props: {
    page: {
      type: String,
      required: true,
    },
    pages: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    predefinedFilter: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isFilterClear () {
      return this.pages[this.page].filter === null
    },
  },
  created () { },
  methods: {
    handleFilterAppend (data) {
      const currentPage = this.pages[this.page]
      if (!currentPage) return

      const currentFilter = extend(true, {}, currentPage.filter || {})
      const incomingData = extend(true, {}, data)

      const filter = Object.assign({}, currentFilter, incomingData)

      let pureFilter = null
      for (const key in filter) {
        if (!Object.prototype.hasOwnProperty.call(filter, key)) continue

        const value = filter[key]
        if (value === null || value === undefined) continue

        pureFilter = pureFilter || {}
        pureFilter[key] = value
      }
      this.$emit('onFilterChanged', { pageName: this.page, filter: pureFilter })
    },
    clearFilter () {
      const { currentFilter } = this.$refs
      if (currentFilter && typeof currentFilter.clearFilter === 'function') {
        currentFilter.clearFilter()
      }

      this.$emit('onFilterChanged', { pageName: this.page, filter: null })
    },
  },
}
